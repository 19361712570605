import React from 'react';
import Product from './Product';

export default function Main(props) {
  const { restaurantsmenu, onAdd } = props;
  return (
    <main className="block product-list">
      <div className="row">
        {restaurantsmenu.map((product) => (
          <Product key={product.food_name} product={product} onAdd={onAdd}></Product>
        ))}
      </div>
    </main>
  );
}
