import React, { useState } from 'react';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import Logo from '../assets/food-logo.png'
import SearchGreenIcon from '../assets/search.png'
import { AppBar, Toolbar, IconButton, Badge, MenuItem, Menu } from '@material-ui/core';
import { ShoppingCart } from '@material-ui/icons';
import { Link, useLocation } from 'react-router-dom';
import useStyles from './styles';

export default function SearchAppBar(props) {
  const classes = useStyles();
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const location = useLocation();

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = () => setMobileMoreAnchorEl(null);

  const mobileMenuId = 'primary-search-account-menu-mobile';

  const renderMobileMenu = (
    <Menu anchorEl={mobileMoreAnchorEl} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} id={mobileMenuId} keepMounted transformOrigin={{ vertical: 'top', horizontal: 'right' }} open={isMobileMenuOpen} onClose={handleMobileMenuClose}>
      <MenuItem>
        <IconButton component={Link} to="/cart" aria-label="Show cart items" color="inherit">
          <Badge badgeContent={props.countCartItems} color="secondary">
            <ShoppingCart />
          </Badge>
        </IconButton>
        <p>Cart</p>
      </MenuItem>
    </Menu>
  );
  return (
    <div className="desktop-navbar">
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <div className='logo p-3'>
            <img src={Logo} alt='Logo' width='100' />
          </div>
          <div className="menus appbar-menu-links">
            <ul>
              <li><Link to="/">Home</Link></li>
              <li><Link to="/restaurants">Restaurants</Link></li>
              <li><Link to="/contact">Contact</Link></li>
            </ul>
          </div>
        
          <div className={classes.search} >
            <div className="custom-search">
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
              />
              <div className="custom-search-icon">
             
          <div className={classes.grow} />
            <div className="cart-icon"> 
               <div className={classes.button}>
                  <IconButton component={Link} to="/cart" aria-label="Show cart items" color="inherit">
                    <Badge badgeContent={props.countCartItems} color="secondary">
                      {/* <ShoppingCart /> */}
                      <img src={SearchGreenIcon} alt='serch foof'/>
                    </Badge>
                  </IconButton>
                </div>
            </div>
              </div>
            </div>
          </div>
        </Toolbar>
      </AppBar>
    </div>
    </div>

  );
}
