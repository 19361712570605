import React from 'react';
import { Link , useHistory} from "react-router-dom";
import { Card, CardContent} from '@material-ui/core';
import CartEmpty from '../assets/giphy.gif';
export default function Basket(props) {
  const history = useHistory();
  const { cartItems, onAdd, onRemove } = props;
  const itemsPrice = cartItems.reduce((a, c) =>{
  const repPrice = c.Price.replace(",",".");
  const finalReplacedPrice = repPrice.replace(" kr.","");
    return a + c.qty * parseFloat(finalReplacedPrice);
  },0);
  const taxPrice = itemsPrice * 0.14;
  const shippingPrice = itemsPrice > 2000 ? 0 : 20;
  const totalPrice = itemsPrice + taxPrice + shippingPrice;
  // console.log('cart items', cartItems);
  return (
    <aside className="block mt-3">
      <Card className="border-top-default">
      <CardContent>
      <h5>Cart Items</h5>
      <div>
        {cartItems.length === 0 && <div className="text-center"><h4 className="text-danger">Cart is empty ...</h4><img src={CartEmpty} width="200" className="my-4"/></div>}
        {cartItems.map((item) => {
          return(
          <div key={item.food_name} className="cart-item d-flex align-items-center w-100 mb-3">
            <div className="w-100 text-left"><h5 className="text-default text-left">{item.food_name}</h5></div>
            <div className="d-flex align-items-center justify-content-center">
              <button onClick={() => onRemove(item)} className="remove">
                -
              </button>{' '}
              <button onClick={() => onAdd(item)} className="add">
                +
              </button>
            </div>
            <div className="text-center w-100">
              <h5 className="text-default text-right">{item.qty} x ${item.Price}</h5>
            </div>
          </div>
        )})}

        {cartItems.length !== 0 && (
          <>
            <hr></hr>
            <div className="d-flex align-items-center justify-content-between">
              <div ><h6 >Items Price</h6></div>
              <div className="text-dark">${itemsPrice.toFixed(2)}</div>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <div ><h6 >Tax Price</h6></div>
              <div className="text-dark">${taxPrice.toFixed(2)}</div>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <div ><h6 >Shipping Price</h6></div>
              <div className="text-dark">
                ${shippingPrice.toFixed(2)}
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-between">
              <div className="">
                <h6 className="text-default"><strong className="text-default">Total Price</strong></h6>
              </div>
              <div className="text-dark">
                <h4><strong className="text-default">${totalPrice.toFixed(2)}</strong></h4>
              </div>
            </div>
            <hr />
            <div className="row">
              <div onClick={() => history.push({
                  pathname: '/checkout',
                  state: {cartItems , totalPrice}
                })} >
                <button className="btn btn-primary w-100 py-3">
                  Continue To Checkout
                </button>
              </div>
            </div>
          </>
        )}
      </div>
      </CardContent>
    </Card>
    </aside>
  );
}
