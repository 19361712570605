import React, { Component } from 'react';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import CardPay from './mastervisa.png';
import AmazonPay from './amazonpay.png';
import CashPay from './cash.png';

export class Success extends Component {
  continue = e => {
    e.preventDefault();
    // PROCESS FORM //
    this.props.nextStep();
  };

  back = e => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    return (
      <MuiThemeProvider>
        <div className="checkout-title">
          <h4> Payment Options -</h4>
        </div>
        <div className="row mt-4">
          <div className="col-md-4">
            <div className="border rounded text-center p-2">
              <img src={CardPay} className="pay-logo"/>
                  <Link to={`/checkout/card`}>
                    <button className="btn btn-primary w-100 py-3">
                      Card 
                    </button>
                  </Link>
            </div>
          </div>
          <div className="col-md-4">
            <div className="border rounded text-center p-2">
                <img src={AmazonPay} className="pay-logo"/>
                  <Link to="#">
                    <button className="btn btn-primary w-100 py-3">
                      Amazon Pay
                    </button>
                  </Link>
            </div>
          </div>
          <div className="col-md-4">
            <div className="border rounded text-center p-2">
                <img src={CashPay} className="pay-logo"/>
                  <Link to='#'>
                    <button className="btn btn-primary w-100 py-3">
                      Cash Pay 
                    </button>
                  </Link>
            </div>
          </div>
        </div>
       
      </MuiThemeProvider>
    );
  }
}

export default Success;
