import React, { useState, useEffect } from 'react';
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import {API, graphqlOperation} from 'aws-amplify';
import { listRestaurants } from './graphql/queries';
import 'bootstrap/dist/css/bootstrap.min.css';
import './app.css'
import Protected from './components/Protected';
import { CssBaseline } from '@material-ui/core';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import { Payment } from "./features/payment/Payment";
import { Preview } from "./features/preview/Preview";
import { Status } from "./features/status/Status";
import { Cancel } from "./features/cancel/Cancel";
import Appbar from './components/Appbar';
import MobileNavbar from './components/MobileNavbar';
import ScrollToTop from './components/ScrollTop';
import Footer from './components/Footer';
import Home from './Pages/Home/Index';
import Dishes from './Pages/Dishes/Index';
import Restaurants from './Pages/Restaurants/Index';
import Contact from './Pages/Contact/Index';
import Checkout from './components/Checkout/UserForm';
import Dashboard from './Dashboard/Views/dashboard/Index';
import RestaurantsDashboard from './Dashboard/Views/restaurants/Index';
import Billig from './Dashboard/Views/billing/Index';
import SignUp from './Dashboard/Views/Auth/SignUp';
import SignIn from './Dashboard/Views/Auth/SignIn';
import Basket from './components/Basket';

Amplify.configure(awsconfig);

const App = () => {
  const [restaurants, setRestaurants] = useState ([]);
  
  useEffect(() => {
    fetchRestaurants();
  }, [])
  
  const fetchRestaurants = async () => {
    try{
      const restaurantData = await API.graphql(graphqlOperation(listRestaurants));
      const restaurantList = restaurantData.data.listRestaurants.items;
      setRestaurants (restaurantList);

    } catch (error){
      console.log('error on fetching restaurants', error)
    }
  }
  //
  
  // shopping cart
 const [cartItems, setCartItems] = useState([]);
 const onAdd = (product) => {
   const exist = cartItems.find((x) => x.id === product.id);
   if (exist) {
     setCartItems(
       cartItems.map((x) =>
         x.id === product.id ? { ...exist, qty: exist.qty + 1 } : x
       )
     );
   } else {
     setCartItems([...cartItems, { ...product, qty: 1 }]);
   }
 };
 const onRemove = (product) => {
   const exist = cartItems.find((x) => x.id === product.id);
   if (exist.qty === 1) {
     setCartItems(cartItems.filter((x) => x.id !== product.id));
   } else {
     setCartItems(
       cartItems.map((x) =>
         x.id === product.id ? { ...exist, qty: exist.qty - 1 } : x
       )
     );
   }
 };
  return (
    <Router basename="/">
      <ScrollToTop />
      <div >
        <CssBaseline />
        <Switch>
        <Route exact path="/preview/:type">
          <Preview />
        </Route>
        <Route exact path="/checkout/:type">
          <Payment />
        </Route>
        <Route exact path="/status/:type">
          <Status />
        </Route>
        <Route exact path="/cancel">
          <Cancel />
        </Route>
        <Route exact path="/payment">
          <div className="main-container">
            <ul className="integration-list">
              <li className="integration-list-item">
                <Link to="/preview/dropin" className="integration-list-item-link">
                  <div className="title-container">
                    <p className="integration-list-item-title">Drop-in</p>
                  </div>
                </Link>
              </li>
              <li className="integration-list-item">
                <Link to="/preview/card" className="integration-list-item-link">
                  <div className="title-container">
                    <p className="integration-list-item-title">Card</p>
                  </div>
                </Link>
              </li>
              <li className="integration-list-item">
                <Link to="/preview/ideal" className="integration-list-item-link">
                  <div className="title-container">
                    <p className="integration-list-item-title">iDEAL</p>
                  </div>
                </Link>
              </li>
              <li className="integration-list-item">
                <Link to="/preview/giropay" className="integration-list-item-link">
                  <div className="title-container">
                    <p className="integration-list-item-title">giropay</p>
                  </div>
                </Link>
              </li>
              <li className="integration-list-item">
                <Link to="/preview/dotpay" className="integration-list-item-link">
                  <div className="title-container">
                    <p className="integration-list-item-title">Dotpay</p>
                  </div>
                </Link>
              </li>
              <li className="integration-list-item">
                <Link to="/preview/eps" className="integration-list-item-link">
                  <div className="title-container">
                    <p className="integration-list-item-title">EPS</p>
                  </div>
                </Link>
              </li>
              <li className="integration-list-item">
                <Link to="/preview/directEbanking" className="integration-list-item-link">
                  <div className="title-container">
                    <p className="integration-list-item-title">Sofort</p>
                  </div>
                </Link>
              </li>
              <li className="integration-list-item">
                <Link to="/preview/bcmc" className="integration-list-item-link">
                  <div className="title-container">
                    <p className="integration-list-item-title">Bancontact card</p>
                  </div>
                </Link>
              </li>
              <li className="integration-list-item">
                <Link to="/preview/paysafecard" className="integration-list-item-link">
                  <div className="title-container">
                    <p className="integration-list-item-title">PaySafe card</p>
                  </div>
                </Link>
              </li>
            </ul>
            <div className="mt-5">
              <Link to="/cancel" className="button text-dark">
                Cancel and Refund a payment
              </Link>
            </div>
          </div>
          </Route>
          <Route exact path="/">
            <Appbar />
              <MobileNavbar />
                <Home />
              <Footer />
          </Route>
          <Route exact path="/dishes" >
            <Appbar />
              <MobileNavbar />
              <Dishes />
            <Footer />
          </Route>
          <Route path="/checkout" exact>
            <Appbar />
              <MobileNavbar />
              <Checkout />
            <Footer />
          </Route>
          <Route exact path="/restaurants">
            <Appbar />
              <MobileNavbar />
            <Restaurants restaurants={restaurants} />
            <Footer />
          </Route>
          <Route exact path="/cart">
            <Appbar />
              <MobileNavbar />
              <Basket cartItems={cartItems} onAdd={onAdd} onRemove={onRemove} ></Basket>
            <Footer />
          </Route>
          <Route exact path="/contact">
            <Appbar />
              <MobileNavbar />
            <Contact  />
            <Footer />
          </Route>
          <Route exact path='/dashboard' >
            <Protected cmp={Dashboard}/>
          </Route>
          <Route exact path='/restaurants_manage' >
            <Protected cmp={RestaurantsDashboard}/>
          </Route>
          <Route exact path='/billing' >
            <Protected cmp={Billig}/>
          </Route>
          <Route exact path="/sign_up">
            <SignUp/>
          </Route>
          <Route exact path="/sign_in">
            <SignIn />
          </Route>
        </Switch>
      </div>
    </Router>
  );
};

export default App;
