import React from 'react'
import FBIcon from '../assets/facebook.png'
import InstaIcon from '../assets/instagram.png'
import TwitterIcon from '../assets/twitter.png'
import Footer_logo from '../assets/footer_logo.png'
function Footer() {
  return (
    <div className="footer_component">
      <div className="row">
        <div className="col-md-4 col-12 text-white">
          <div>
            <div>
              <img src={Footer_logo} width='150' alt="food by home" />
            </div>
            <p className="mt-3">
              2058  Sugar Camp Road <br/>
              Blue Earth <br/>
              Minnesota <br/><br/>

              Phone Number- 507-525-8599<br/>
              Mobile Number- 507-600-7120

            </p>
            <p>qr3x56bgtkg@temporary-mail.net</p>
            <div className="d-flex">
            <p className='m-0'>Follow us on :-</p> 
              <img src={FBIcon} width='20' height='20' className="m-1" alt="facebook"/>
              <img src={InstaIcon} width='20' height='20' className="m-1" alt="Instagram"/>
              <img src={TwitterIcon} width='20' height='20' className="m-1" alt="Twitter"/>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-12">
          <div className="footer_links text-white">
            <h4 className="text-white"><b>Food</b></h4>
            <ul className="mt-4">
              <li>  Tacos</li>
              <li>  Poutine</li>
              <li>  Chicken rice</li>
              <li>  Som tam</li>
              <li>  Seafood paella</li>
              <li>  Potato chips</li>
              <li>  Masala dosa</li>
              <li>  Buttered popcorn</li>
            </ul>
          </div>
        </div>
        <div className="col-md-4 col-12">
          <div className="footer_links text-white">
            <h4 className="text-white"><b>Restaurants</b></h4>
            <ul className="mt-4">
              <li>  Ithaa. Rangali Island, </li>
              <li>  Dinner in the Sky.</li>
              <li>  Parallax Restaurant</li>
              <li>  El Diablo</li>
              <li>  The Disaster Café</li>
              <li>  Planet of the Grapes</li>
              <li>  Eatmore Fried Chicken</li>
              <li>  Dinner in the Sky.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer;
