import React from 'react'
import Banner from '../../assets/banner01.png'
import OrderIcon1 from '../../assets/Setlocation.png'
import OrderIcon2 from '../../assets/Selectdish.png'
import OrderIcon3 from '../../assets/Checkout.png'
import Mobile from '../../assets/mobileapp.png'
import Appstore1 from '../../assets/app-store.png'
import Appstore2 from '../../assets/playstore.png'
import BenifitIcon1 from '../../assets/getbonus.png'
import BenifitIcon2 from '../../assets/warranty.png'
import BenifitIcon3 from '../../assets/benifits.png'
import Desktop from '../../assets/desktop.png'
import './Index.css'
import Subscription from '../../components/Pricing/Index'

function Index() {
  return (
    <div className="home-component">
      <section className="top-banner">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-12 d-flex align-items-center justify-content-center">
              <div className="banner_left">
                  <div className="section-title">
                    <h2>Enjoy Your <br/>Delicious Meal </h2>
                  </div>
                  <div className="section-desc">
                    <p>
                    Food from the best Restaurants to your doorstep <br />
                    Order your favourite restaurant or dish <br />
                    and we will deliver it you in no time...
                    </p>
                  </div>
                  <div className="d-flex">
                    <button className="btn btn-primary m-2">Explore Restaurants</button>
                    <button className="btn btn-default m-2">Explore Menu</button>
                  </div>
              </div>
            </div>
            <div className="col-md-6 col-12">
                <div className="banner_right">
                  <div className="d-flex align-items-center justify-content-center">
                    <img className="w-100" src={Banner} alt='banner_img' />
                  </div>
                </div>
            </div>
        </div>
        </div>
      </section>
      <section className="Order-Step-Section bg-grey">
        <div className="container">
          <div className="text-center">
            <h3>Order the food</h3>
            <h3 className="text-default">In 3 Simple Steps !</h3>
          </div>
          <div className="row mt-5">
            <div className="col-md-4 col-12">
              <div className="text-center">
                <div className="order_step_content p-2">
                  <img src={OrderIcon1} alt='order-icon' />
                  <h3 className="mt-2">Set Location</h3>
                  <p>Add your location <br />to get the food delivered</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="text-center">
                <div className="order_step_content p-2">
                  <img src={OrderIcon2} alt='order-icon' />
                  <h3 className="mt-4">Select Dish</h3>
                  <p>Add your location <br />to get the food delivered</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="text-center">
                <div className="order_step_content p-2">
                  <img src={OrderIcon3} alt='order-icon' />
                  <h3 className="mt-2">Checkout</h3>
                  <p>Add your location <br />to get the food delivered</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="appstore_banner pt-5">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-12 d-flex align-items-center justify-content-center">
              <div>
                <div className="section-title">
                  <h2 className="appstore-title">Download the App <br/>Click and <br/>Enjoy your Meal</h2>
                </div>
                <div className="d-flex align-items-center justify-content-center w-100">
                  <img className="appstore-logos m-2" src={Appstore1} alt='Android-appstore' />
                  <img className="appstore-logos m-2" src={Appstore2} alt='apple-appstore'/>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="mobile-img d-flex align-items-center justify-content-center">
                <img src={Mobile} alt="mobile-app" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="benifits-section">
        <div className="container">
          <div className="benifits-section-content">
            <div className="row">
              <div className="col-md-4 col-12">
                <div className="benifit-box">
                  <div className="benifit-icon">
                    <img src={BenifitIcon1} alt='food-benifit' />
                  </div>
                  <div className="benifit-desc">
                    <h4>Get Bonus</h4>
                    <p className="mt-5">Get stamps, promotions, discounts,<br/>
                        news and more through our <br/>
                        newsletters and social channels</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="benifit-box">
                  <div className="benifit-icon">
                    <img src={BenifitIcon2} alt='food-benifit' />
                  </div>
                  <div className="benifit-desc">
                    <h4>Your Warranty</h4>
                    <p className="mt-5">Great service, absolutely free <br />Authentic user reviews</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="benifit-box">
                  <div className="benifit-icon">
                    <img src={BenifitIcon3} alt='food-benifit' />
                  </div>
                  <div className="benifit-desc">
                    <h4>Your Benifits</h4>
                    <p className="mt-5">2600+ partner restaurants to choose from Pay in cash or online Order where you want, whenever you want</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="want_more_section">
          <div className="container">
          <div className="row">
            <div className="col-md-6 col-12 d-flex align-items-center justify-content-center">
              <div className="text-center">
                <div className="section-title">
                  <h2 className="appstore-title">Want More ?</h2>
                </div>
                <div className="section-desc mt-4">
                  <p style={{fontSize:"1.5rem"}}>Check out our exciting articles on <br/>food and lifestyle on our blog.</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="mobile-img d-flex align-items-center justify-content-center">
                <img className="w-100" src={Desktop} alt="mobile-app" />
              </div>
            </div>
          </div>
          </div>
        </div>
      </section>
      <section className="subscription-section p-5 ps-0 pe-0">
        <div className="container-fluid">
          <div className="section-title">
            <h2 className="appstore-title">Subscription Plans ..</h2>
          </div>
          <div className="row">
            <div className="col-md-7 col-12 p-0">
              <div className="subcription-right">
                {/* <img src={PricingImage} alt="PricingImage" className="w-100"/> */}
              </div>
            </div>
            <div className="col-md-5 col-12">
              <div className="subcription-left">
                <Subscription/>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Index
