import { Auth } from 'aws-amplify';
import React, {useState} from 'react';
import { Link, useHistory } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';
import '../../assets/css/soft-ui-dashboard.css';
import '../../assets/css/soft-ui-dashboard.min.css';
import './Style.css';

const SignIn = ({onSignin}) => {
  const [errorStatus, seterrorStatus] = useState(false);
  const [successerrorStatus, setsuccesserrorStatus] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();

  //error toast
  const ToastError = () => {
    return (
      <div className="mb-3">
        <Alert severity="error">
          <AlertTitle>Incorrect Username & Password</AlertTitle>
          Please Enter Correct Username & Password !
        </Alert>
      </div>
    )
};
 //Success toast
 const ToastSuccess = () => {
  return (
    <div className="mb-3">
      <Alert severity="success">
        <AlertTitle>Congrates Login Success</AlertTitle>
      </Alert>
    </div>
  )
};
// sign in
  const signIn = async () => {
    try {
      const user = await Auth.signIn(username, password);
      setsuccesserrorStatus(true);
      history.push('/dashboard');
      onSignin();
    } catch (error) {
      // console.log('error signing in', error);
      seterrorStatus(true);
    }
  };
 
  return (
    <div className="signin-page">
      <section className="min-vh-100 mb-8">
        <div className="page-header auth-page-header align-items-start min-vh-50 pt-5 pb-11 m-3 border-radius-lg">
          <span className="mask bg-gradient-dark opacity-6"></span>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-5 text-center mx-auto">
                {/* <h1 className="text-white mb-2 mt-5">Welcome To </h1> */}
                <h1 className="text-dark">Welcome Back</h1>
                <p className="text-lead text-white">Enter your email and password to sign in</p>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row mt-lg-n10 mt-md-n11 mt-n10">
            <div className="col-xl-4 col-lg-5 col-md-7 mx-auto">
              <div className="card z-index-0">
                <div className="card-header text-center pt-4">
                  <h3 className="text-default">Login </h3>
                </div>
                
                <div className="card-body">
                {errorStatus ? <ToastError/> : null}
                {successerrorStatus ? <ToastSuccess/> : null}

                  <form >
                    <div className="mb-3">
                      <input type="text" name="username" className="form-control" placeholder="Username" aria-label="Username" aria-describedby="username-addon" value={username} onChange={e => setUsername(e.target.value)} />
                    </div>
                    <div className="mb-3">
                      <input type="password" name="password" className="form-control" placeholder="Password" aria-label="Password" aria-describedby="password-addon" value={password} onChange={e => setPassword(e.target.value)} />
                    </div>
                  
                    <div className="text-center">
                      <button type="button" className="btn btn-primary w-100 my-4 mb-2" onClick={signIn}>Sign In</button>
                    </div>
                    <p className="text-sm mt-3 mb-0">Don't have an account? <Link to="sign_up" className="text-default font-weight-bolder">Sign Up</Link></p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default SignIn
