// CheckoutForm.js
import React, { Component } from "react";
import { injectStripe } from "react-stripe-elements";
import {
	CardNumberElement,
	CardExpiryElement,
	CardCVCElement,
} from "react-stripe-elements";
import axios from 'axios';
import Loader from '../../components/Loader/Index'
const createOptions = () => {
	return {
		style: {
			base: {
				width: 'calc(50% - 10px)',
				padding: '12px',
				backgroundColor: '#fff',
				color: '#85b767',
			},
		},
	};
};
class CardForm extends Component {
	state = {
		loading: false,
		errorMessage: "",
		name: "",
		amount: "",
	};

	handleChange = ({ error }) => {
		if (error) {
			this.setState({ errorMessage: error.message });
		}
	};
	handleChangeName = (e) => {
		this.setState({ name: e.target.value });
	};
	handleChangeAmount = (e) => {
		this.setState({ amount: e.target.value });
	};
	makePayment = (e) => {
		e.preventDefault();
		this.setState({ loading: true });
		this.props.stripe.createToken().then((payload) => {
			const token = payload.token.id;
			let data = {};
			data["stripeToken"] = token;
			data["name"] = this.state.name
			data["amount"] = this.state.amount
			if(this.state.amount === ""){
				axios.post(`${process.env.REACT_APP_API_URL}/api/v1/charges`, data).then((response) => {
					if (response.status === 200) {
						if (response.data.success) {
							this.setState({ loading: false });
							setTimeout(() => {
								window.location.href = "/restaurants";
							}, 2000);
						} else {
							this.setState({ loading: false });
							window.location.href = "/restaurants";
						}
					}
					else {
						this.setState({ loading: false });
						window.location.href = "/restaurants";
					}
				})
			}
			else{
				axios.post(`${process.env.REACT_APP_API_URL}/api/v1/order`, data).then((response) => {
					if (response.status === 200) {
						if (response.data.success) {
							this.setState({ loading: false });
							setTimeout(() => {
								window.location.href = "/restaurants";
							}, 2000);
						} else {
							this.setState({ loading: false });
							window.location.href = "/restaurants";
						}
					}
					else {
						this.setState({ loading: false });
						window.location.href = "/restaurants";
					}
				})
			}
		});
	};
	render() {
		return (
			<React.Fragment>
				<form onSubmit={this.makePayment.bind(this)}>
					<div className="split-form">
						<div className="row">
							<label>
								<CardNumberElement
									{...createOptions()}
									onChange={this.handleChange}
								/>
							</label>
						</div>
						<div className="row">
							<label>
								<CardExpiryElement
									{...createOptions()}
									onChange={this.handleChange}
								/>
							</label>
							<label>
								<CardCVCElement
									{...createOptions()}
									onChange={this.handleChange}
									class="textb"
								/>
							</label>
							<label style={{ width: "100%" }}>
								<br />
								<input type="text" class="textb" placeholder="Restaurant Name" onChange={this.handleChangeName.bind(this)} />
							</label>
							{this.props.type === "order" ?
								<label style={{ width: "100%" }}>
									<br />
									<input type="text" class="textb" placeholder="Order Amount" onChange={this.handleChangeAmount.bind(this)} />
								</label>
								: null}
						</div>
					</div>
					{this.state.loading === true ? (
						<div style={{ textAlign: "center", fontWeight: "600" }}>
							<Loader />
						</div>
					) : null}
					{this.props.type === "subscription" ?
						<button className="btn btn-primary m-2">Subscribe</button>
						: null}
					{this.props.type === "order" ?
						<button className="btn btn-primary m-2">Order</button>
						: null}
				</form>
			</React.Fragment>
		);
	}
}

export default injectStripe(CardForm);
