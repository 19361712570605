import React, { useState, useEffect } from 'react';
import {API, Storage, graphqlOperation} from 'aws-amplify';
import {v4 as uuid} from 'uuid';
import Navbar from './Navbar';
import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import DummyLogo from '../../assets/img/burger.png';
import UserIcon from '../../assets/img/user-icon.png';
import { listRestaurants } from '../../../graphql/queries';

import {createRestaurant, updateRestaurant} from '../../../graphql/mutations';
import NologoImage from './nologo.png';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';

function Index() {
  const [restaurants, setRestaurants] = useState ([]);


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(restaurants, comparator) {
  const stabilizedThis = restaurants.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'name', numeric: false, disablePadding: true, label: 'Restaurant Name' },
  { id: 'categories', numeric: true, disablePadding: false, label: 'Categories' },
  { id: 'city', numeric: true, disablePadding: false, label: 'City' },
  { id: 'rating', numeric: true, disablePadding: false, label: 'Rating' },
  { id: 'action', numeric: false, disablePadding: false, label: 'Actions' },

];

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          Restaurant List
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('categories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = restaurants.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, restaurants.length - page * rowsPerPage);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //fetch Restaurants


  useEffect(() => {
    fetchRestaurants();
  }, [])
 
  const fetchRestaurants = async () => {
    try{
      const restaurantData = await API.graphql(graphqlOperation(listRestaurants));
      const restaurantList = restaurantData.data.listRestaurants.items;
      // console.log('restaurant list', restaurantList);
      setRestaurants (restaurantList)
    } catch (error){
      console.log('error on fetching restaurants', error)
    }
  }
  //Add Restaurant

  const AddRestaurant = ({onUpload}) => { 
    const [restautantData, setRestautantData] = useState({});
    const [fileData, setFileData] = useState();
   
    const uploadRestaurant = async (e) => {
      e.preventDefault();
      //upload the restaurant
      setOpen(false);

      console.log('restautantData', restautantData);
      // console.log('file', fileData.name);
      const {name, categories, address, city, zipCode, rating, minDelivery, duration, deliveryCost, priceRating, phone} = restautantData;
      // const { key } = await Storage.put(`${uuid()}-restaurant.png`, fileData, { contentType: 'image/png' });
      // await Storage.put(fileData.name, fileData, {
      //   contentType: 'image/png' // contentType is optional
      // });
        
      const createRestaurantInput = {
        id: uuid(),
        name,
        categories,
        address,
        city,
        zipCode,
        rating,
        minDelivery,
        duration,
        deliveryCost,
        priceRating,
        phone,
        rating: 0
      }
      await API.graphql(graphqlOperation(createRestaurant, {input: createRestaurantInput}))
      onUpload();
    }

    return(
      <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Add Restaurant"}</DialogTitle>
        <DialogContent>
          <div className="add_restaurant_form" style={{width:"500px"}}>
            <form className="w-100">
              <div className="form-row mt-3">
                <lable>Restaurant Name :</lable>
                <input type="text" name="name" className="form-control" value={restautantData.name} onChange={e => setRestautantData({ ...restautantData, name: e.target.value })}/>
              </div>
              <div className="form-row mt-3">
                <lable>Categories :</lable>
                <input type="text" name="categories" className="form-control" value={restautantData.categories} onChange={e => setRestautantData({ ...restautantData, categories: e.target.value })}/>
              </div>
              <div className="form-row mt-3">
                <lable>Address :</lable>
                <input type="text" name="address" className="form-control" value={restautantData.address} onChange={e => setRestautantData({ ...restautantData, address: e.target.value })}/>
              </div>
              <div className="form-row mt-3">
                <lable>City :</lable>
                <input type="text" name="city" className="form-control" value={restautantData.city} onChange={e => setRestautantData({ ...restautantData, city: e.target.value })}/>
              </div>
              <div className="form-row mt-3">
                <lable>zipCode :</lable>
                <input type="text" name="zipCode" className="form-control" value={restautantData.zipCode} onChange={e => setRestautantData({ ...restautantData, zipCode: e.target.value })}/>
              </div>
              <div className="form-row mt-3">
                <lable>Rating :</lable>
                <input type="text" name="rating" className="form-control" value={restautantData.rating} onChange={e => setRestautantData({ ...restautantData, rating: e.target.value })}/>
              </div>
              <div className="form-row mt-3">
                <lable>Min Delivery :</lable>
                <input type="text" name="minDelivery" className="form-control" value={restautantData.minDelivery} onChange={e => setRestautantData({ ...restautantData, minDelivery: e.target.value })}/>
              </div>
              <div className="form-row mt-3">
                <lable>Duration :</lable>
                <input type="text" name="duration" className="form-control" value={restautantData.duration} onChange={e => setRestautantData({ ...restautantData, duration: e.target.value })}/>
              </div>
             
              <div className="form-row mt-3">
                <lable>Delivery Cost :</lable>
                <input type="text" name="deliveryCost" className="form-control" value={restautantData.deliveryCost} onChange={e => setRestautantData({ ...restautantData, deliveryCost: e.target.value })}/>
              </div>
              <div className="form-row mt-3">
                <lable>priceRating :</lable>
                <input type="text" name="priceRating" className="form-control" value={restautantData.priceRating} onChange={e => setRestautantData({ ...restautantData, priceRating: e.target.value })}/>
              </div>
              {/* <div className="form-row mt-3">
                <lable>phone :</lable>
                <input type="text" name="phone" className="form-control" value={restautantData.phone} onChange={e => setRestautantData({ ...restautantData, phone: e.target.value })}/>
              </div> */}
              {/* <div className="form-row mt-3">
                <lable>Restaurant Image :</lable>
                <input type="file" className="form-control" onChange={e => setFileData(e.target.files[0])}/>
              </div> */}
              <div className="form-row mt-3 ">
                <button class="btn btn-primary" onClick={uploadRestaurant}>Add Restaurant</button>
                <button class="btn btn-danger ms-3" onClick={handleClose}>Cancel</button>
              </div>
            </form>
          </div>
        </DialogContent>
      </Dialog>
    </div>
    )
  }
  return (
    <div>
      <Navbar />
      <div class="container-fluid py-4">
      <div class="row">
        <div class="col-12">
          <div class="card mb-4">
            <div class="card-header pb-0 d-flex align-items-center justify-content-end w-100">
              <button className="btn btn-primary" onClick={handleClickOpen}>+ Add Restaurant</button>
            </div>
            <div>
              <AddRestaurant  onUpload={() => {fetchRestaurants()}}/>
            </div>
            
            <div>
            <div className={classes.root}>
      <div className={classes.paper}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={restaurants.length}
            />
            <TableBody>
              {stableSort(restaurants, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((restaurant, index) => {
                  const isItemSelected = isSelected(restaurant.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, restaurant.name)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={restaurant.name}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                      <div>
                        {(restaurant.logo!="''" && restaurant.logo!="") ? <img alt="Resaturant-img" src={restaurant.logo}   className="avatar avatar-sm rounded-circle me-2" />:<img alt="Resaturant-img" src={NologoImage}   className="avatar avatar-sm rounded-circle me-2" /> 
                      }
                        {restaurant.name}

                      </div>
                      </TableCell>
                      <TableCell align="right">{restaurant.categories}</TableCell>
                      <TableCell align="right">{restaurant.city}</TableCell>
                      <TableCell align="right">
                        <Box component="fieldset" mb={3} borderColor="transparent" className="mb-0">
                          <Rating name="read-only" value={restaurant.rating} readOnly />
                        </Box>
                      </TableCell>
                      <TableCell align="right" className="d-flex">
                        <button className="btn btn-sm btn-primary m-2">Edit</button> 
                        <button className="btn btn-sm btn-danger m-2">Delete</button>
                      </TableCell>

                    </TableRow>
                  );
                   
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={restaurants.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
      <FormControlLabel 
        control={<Switch className="ms-3" checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </div>
    
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}

export default Index
