import React, { useState , useEffect} from 'react'
import { Redirect } from 'react-router-dom';
import { Auth } from 'aws-amplify';

function Protected(props) {
  const [loggedIn, setLoggedIn] = useState(true);
  const Cmp = props.cmp
 // Login
const assessLoggedInState = () => {
  Auth.currentAuthenticatedUser()
  .then(sess => {
    console.log('logged in');
    setLoggedIn(true);
  })
  .catch(() => {
    console.log('not logged in');
    setLoggedIn(false);
  })
};
useEffect(() => {
  assessLoggedInState();
}, []);
  return(<div>{ loggedIn ?  <Cmp /> : <Redirect to="/sign_in" /> }</div>)
}

export default Protected
