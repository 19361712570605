import React, {useState} from 'react';
import {useLocation} from 'react-router-dom';
import Menus from '../../components/Menus';
import Basket from '../../components/Basket';
import './Index.css';
const Products = () => {
const {state} = useLocation();
const { itemMenu } = state;

 // shopping cart
 const [cartItems, setCartItems] = useState([]);
 const onAdd = (product) => {
   const exist = cartItems.find((x) => x.id === product.id);
   if (exist) {
     setCartItems(
       cartItems.map((x) =>
         x.id === product.id ? { ...exist, qty: exist.qty + 1 } : x
       )
     );
   } else {
     setCartItems([...cartItems, { ...product, qty: 1 }]);
   }
 };
 const onRemove = (product) => {
   const exist = cartItems.find((x) => x.id === product.id);
   if (exist.qty === 1) {
     setCartItems(cartItems.filter((x) => x.id !== product.id));
   } else {
     setCartItems(
       cartItems.map((x) =>
         x.id === product.id ? { ...exist, qty: exist.qty - 1 } : x
       )
     );
   }
 };
  return (
    <div className="dishes-shop-component ">
      <div className="page-title-banner">
        <div className="text-center banner-page-title">
          <h2>Dishes</h2>
          <h4 className="text-white">Total Dishes - <span>{itemMenu.length}</span></h4>
        </div>
      </div>
      <div className="container">
        <div className="my-5 row">
          <div className="col-md-6">
            <Menus restaurantsmenu={itemMenu} onAdd={onAdd}></Menus>
          </div>
          <div className="col-md-6">
            <Basket cartItems={cartItems} onAdd={onAdd} onRemove={onRemove} ></Basket>
          </div>
        </div>
        <div>
        </div>
      </div>
    </div>
  
  );
};

export default Products;

