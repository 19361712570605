import React from 'react';
import ReactDOM from 'react-dom';
import store from "./app/store";
import App from './App';
import { Provider } from "react-redux";
import Amplify from 'aws-amplify'
import config from './aws-exports'
Amplify.configure(config)
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>, 
  document.getElementById('root')
  );
