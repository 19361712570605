/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_appsync_graphqlEndpoint": "https://jqy2lmq3tnf5dbu33fw3ztx6wq.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-5ueoxkfyovczrlrtjzxzghqzta",
    "aws_cognito_identity_pool_id": "eu-central-1:33b5a315-3b25-4be2-b51e-62a30ca0796d",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_85IuaDNYN",
    "aws_user_pools_web_client_id": "cmh4n551onm789aq5v7e6m89q",
    "oauth": {},
    "aws_user_files_s3_bucket": "restaurants-logo-bucket132207-staging",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
