import React from 'react'
import Aside from '../components/Sidebar'
import Dashboard from '../components/Dashboard'
import './Index.css'
function Index() {
  return (
    <div>
      <div className="row">
        <div className="col-md-2 col-12">
          <Aside/>
        </div>
        <div className="col-md-10 col-12">
          <Dashboard/>
        </div>
      </div>
    </div>
  )
}

export default Index
