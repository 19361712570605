import React from 'react'
import './Index.css'
import HomeIcon from '@material-ui/icons/Home';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
function Index() {
  return (
    <div className="ContactPage">
      <div class="contact-page">
        <div className="section-title text-center">
          <h2>Get in touch</h2>
        </div>
        <div className="d-flex mt-5 align-items-center justify-content-around" style={{height:"400px"}}>
          <div class="contact-info">
            <div class="item">
              <HomeIcon />
              <span className="ms-4">New York, United States</span>
            </div>
            <div class="item">
              <PhoneIcon/>
              <span className="ms-4">+0 000 0000000</span>
            </div>
            <div class="item">
              <EmailIcon/>
              <span className="ms-4">email@address.com</span>
            </div>
            <div class="item">
              <QueryBuilderIcon/>
              <span className="ms-4">Mon - Fri 8:00 AM to 6:00 PM</span>
            </div>
          </div>
          <form action="" class="contact-form">
            <input type="text" class="textb" placeholder="Your Name" />
            <input type="email" class="textb" placeholder="Your Email" />
            <textarea placeholder="Your Message"></textarea>
            <button className="btn btn-primary">Submit</button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Index
