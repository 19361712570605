import React, { useState, useEffect } from 'react';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { useHistory } from 'react-router-dom';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import Loader from '../../components/Loader/Index'
import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';
import './Index.css';
import NologoImage from './nologo.png';
import { StripeProvider } from "react-stripe-elements";
import MyStoreCheckout from "./MyStoreCheckout";

function Index({ restaurants }) {
  // console.log('rest', restaurants);
  const history = useHistory();

  const onboard = () => {
    window.location = `https://connect.stripe.com/express/oauth/v2/authorize?redirect_uri=https://connectsystem.herokuapp.com/stripe/connect&client_id=${process.env.REACT_APP_STRIPE_CLIENT_ID}`
  }

  return (
    <div className="mb-5">
      <div className="page-title-banner">
        <div className="text-center banner-page-title">
          <h2>Restaurants</h2>
        </div>
      </div>

      <div className="container-fluid mt-5">
        <h4 className="text-default"><b>Best Restaurants  </b><LocationOnIcon /></h4>
        <button className="btn btn-primary m-2" onClick={onboard}>Onboard</button>
        <br /><br />
        <StripeProvider apiKey={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}>
          <MyStoreCheckout
            type="subscription"
          />
        </StripeProvider>
        <StripeProvider apiKey={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}>
          <MyStoreCheckout
          type="order"
          />
        </StripeProvider>
        {restaurants == '' ?
          <Loader />
          :
          <div className="card p-4 pt-0">
            <div className="row">
              {restaurants.map(restaurant => {
                const itemMenu = eval(restaurant.menu);
                return (
                  <>
                    <div className="col-lg-4 col-12 restaurants mt-5">
                      <div className="cursor-pointer" onClick={() => history.push({
                        pathname: '/dishes',
                        state: { itemMenu }
                      })} >
                        <div className="restaurant-img">
                          {(restaurant.logo !== "''" && restaurant.logo !== "") ? <img alt="Resaturant-img" src={restaurant.logo} className="img-fluid" /> : <img alt="Resaturant-img" src={NologoImage} className="img-fluid" />
                          }
                        </div>
                        <div className="px-2 restaurant-description">
                          <div>
                            <h4 className="text-default mt-3">{restaurant.name}</h4>
                            <p className="mt-3 mb-0">Categories - <b>{restaurant.categories}</b></p>
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                            <p className="m-0 mb-0">City- </p> <p className="m-0 text-default"><b>{restaurant.city}</b></p>
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                            <Box component="fieldset" mb={3} borderColor="transparent" className="mb-0">
                              <Rating name="read-only" value={restaurant.rating} readOnly />
                            </Box>
                            <span className="flr">(Delivery Reviews)</span>

                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                            <p className="m-0 mb-0"><b className="text-default">PriceRate-{restaurant.priceRating}</b></p> <p className="m-0 ">Duration - <b className="text-default">{restaurant.duration} <AccessTimeIcon /></b></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        }
      </div>
      <div>

      </div>
    </div>
  );
}

export default Index
