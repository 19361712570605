import React, { Component } from 'react';
import FormUserDetails from './FormUserDetails';
import FormPersonalDetails from './FormPersonalDetails';
import Confirm from './Confirm';
import Success from './Success';
import Cart from './CartItems';
import './styles.css';

export class UserForm extends Component {
  state = {
    step: 1,
    firstName: '',
    lastName: '',
    email: '',
    occupation: '',
    city: '',
    bio: ''
  };

  // Proceed to next step
  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1
    });
  };

  // Go back to prev step
  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1
    });
  };

  // Handle fields change
  handleChange = input => e => {
    this.setState({ [input]: e.target.value });
  };
 
  render() {
    const { step } = this.state;
    const { firstName, lastName, email, occupation, city, bio } = this.state;
    const values = { firstName, lastName, email, occupation, city, bio };
   
    switch (step) {
      case 1:
        return (
          <div className="container my-5">
            <div className="card p-3">
              <div className="row">
                <div className="col-md-7">
                  <FormUserDetails
                    nextStep={this.nextStep}
                    handleChange={this.handleChange}
                    values={values}
                  />
                </div>
                <div className="col-md-5">
                  <Cart />
                </div>
              </div>
            </div>  
          </div>
        );
      case 2:
        return (
          <div className="container my-5">
            <div className="card p-3">
            <div className="row">
                <div className="col-md-7">
                    <FormPersonalDetails
                    nextStep={this.nextStep}
                    prevStep={this.prevStep}
                    handleChange={this.handleChange}
                    values={values}
                  />
                </div>
                <div className="col-md-5">
                  <Cart />
                </div>
              </div>
              
            </div>  
          </div>
         
        );
      case 3:
        return (
          <div className="container my-5">
            <div className="card p-3">
            <div className="row">
                <div className="col-md-7">
                  <Confirm
                    nextStep={this.nextStep}
                    prevStep={this.prevStep}
                    values={values}
                  />
                </div>
                <div className="col-md-5">
                  <Cart />
                </div>
              </div>
            </div>  
          </div>
          
        );
      case 4:
        return (
          <div className="container my-5">
            <div className="card p-3">
              <Success />
            </div>  
          </div>
        ) ;
      default:
        (console.log('This is a multi-step form built with React.'))
    }
  }
}

export default UserForm;

