import React from 'react'
import './Index.css'
import StarsIcon from '@material-ui/icons/Stars';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
export default function Index() {
  return (
    <div>
      <div class="pricing-section">
        <div class="container">
            <div class="outer-box">
                <div class="row">
                    <div class="pricing-block col-lg-12 col-md-12 col-sm-12 wow fadeInUp">
                        <div class="inner-box">
                            <div class="icon-box">
                                <div class="icon-outer"><StarsIcon/></div>
                            </div>
                            <div class="price-box">
                                <div class="title"> 1 Month Pass</div>
                                <h4 class="price">$20.00</h4>
                            </div>
                            <ul class="features">
                                <li class="true"><CheckCircleIcon/> <span className="ms-2">Conference plans</span></li>
                                <li class="true"><CheckCircleIcon/> <span className="ms-2"> Free Lunch And Coffee </span></li>
                                <li class="true"><CheckCircleIcon/><span className="ms-2"> Certificate </span></li>
                                <li class="false"><CancelIcon/> <span className="ms-2">Easy Access</span></li>
                                <li class="false"><CancelIcon/> <span className="ms-2">Free Contacts</span></li>
                            </ul>
                            <div class="btn-box">
                                <a href="/#"class="theme-btn">BUY plan</a>
                            </div>
                        </div>
                    </div>

                    {/* <div class="pricing-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="400ms">
                        <div class="inner-box">
                            <div class="icon-box">
                                <div class="icon-outer"><i class="fas fa-gem"></i></div>
                            </div>
                            <div class="price-box">
                                <div class="title">Full Pass</div>
                                <h4 class="price">$99.99</h4>
                            </div>
                            <ul class="features">
                                <li class="true">Conference plans</li>
                                <li class="true">Free Lunch And Coffee</li>
                                <li class="true">Certificate</li>
                                <li class="true">Easy Access</li>
                                <li class="false">Free Contacts</li>
                            </ul>
                            <div class="btn-box">
                                <a href="/#"class="theme-btn">BUY plan</a>
                            </div>
                        </div>
                    </div>

                    <div class="pricing-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="800ms">
                        <div class="inner-box">
                            <div class="icon-box">
                                <div class="icon-outer"><i class="fas fa-rocket"></i></div>
                            </div>
                            <div class="price-box">
                                <div class="title">Group Pass</div>
                                <h4 class="price">$199.99</h4>
                            </div>
                            <ul class="features">
                                <li class="true">Conference plans</li>
                                <li class="true">Free Lunch And Coffee</li>
                                <li class="true">Certificate</li>
                                <li class="true">Easy Access</li>
                                <li class="true">Free Contacts</li>
                            </ul>
                            <div class="btn-box">
                                <a href="/#"class="theme-btn">BUY plan</a>
                            </div>
                        </div>
                    </div> */}

                </div>
            </div>
        </div>
    </div>
    </div>
  )
}
