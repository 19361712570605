import React from 'react';
import { Card, CardContent, CardActions, Typography } from '@material-ui/core';

export default function Product(props) {
  const { product, onAdd } = props;
  return (
    <div className="mt-3">
    <Card className="border-top-default">
      <CardContent>
        <div className="d-flex justify-content-between align-items-center">
          <Typography gutterBottom variant="h6" component="h6"> <span className="text-default"> <b>Food Name - </b></span>
          </Typography>
          <Typography gutterBottom variant="h6" component="h6">{product.food_name}</Typography>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <Typography gutterBottom variant="h6" component="h6"> <span className="text-default"> <b>Price - </b></span>
          </Typography>
          <Typography gutterBottom variant="h6" component="h6">$ {product.Price}</Typography>
        </div>
        <div>
          <Typography gutterBottom variant="h6" component="h6"> <span className="text-default"> <b>Description - </b></span>
          </Typography>
          <Typography gutterBottom variant="p" component="p">{product.Discription}</Typography>
        </div>
      </CardContent>
      <CardActions disableSpacing>
        <button className="btn btn-primary" onClick={()=>onAdd(product)}> Buy Now</button>
      </CardActions>
    </Card>
  </div>
  );
}
