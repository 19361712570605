import React, { Component } from "react";
import { Elements } from "react-stripe-elements";
import CardForm from "./CheckoutForm";
class MyStoreCheckout extends Component {
  render() {
    return (
      <div className="Checkout">
        <Elements>
          <CardForm type={this.props.type}/>
        </Elements>
      </div>
    );
  }
}

export default MyStoreCheckout;
