import React, {useState} from 'react';
import {useLocation, useHistory} from 'react-router-dom';

function CartItems() {
    const {state} = useLocation();
    const { cartItems, totalPrice } = state;
    return (
        
        <div>
            <h4 className="mb-3">Your Cart Items</h4>
            <table className="w-100 CartTable">
                <tbody>
                    <tr>
                        <th className="text-start"><h5>Food Name</h5></th>
                        <th className="text-end"><h5>Price</h5></th>
                    </tr>
                {cartItems.map((item) => {
                    return(
                        <tr>
                            <td>
                            <div key={item.food_name} className="cart-item d-flex align-items-center w-100 m-0">
                                <div className="w-100 text-left"><h6 className="text-left">{item.food_name}</h6></div>
                            </div>
                            </td>
                            <td>
                            <div>
                                <div className="text-center w-100">
                                <h6 className="text-end">{item.qty} x ${item.Price}</h6>
                                </div> 
                            </div>
                            </td>
                           
                        </tr>
                    )})}
                    <tr style={{borderTop:"1px solid #85b767"}}>
                        <td className="text-start "><h5 className="text-default">Total </h5></td>
                        <td className="text-end "><h5 className="text-default">$ {totalPrice}  kr.</h5></td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default CartItems
